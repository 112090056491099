import { useAuthenticator } from '@aws-amplify/ui-react';
import { createContext, ReactElement, useCallback, useEffect, useState } from 'react';
import { VariantModel } from '../../models/variant.model';

interface VariantsContextType {
  loading?: boolean;
  variants?: VariantModel[];
  getVariants?: () => Promise<void>;
}

export const VariantsContext = createContext<VariantsContextType>({});

interface VariantsProviderProps {
  children: ReactElement;
}

export const VariantsProvider = ({ children }: VariantsProviderProps) => {
  const { user } = useAuthenticator();
  const [loading, setLoading] = useState<boolean>(false);
  const [variants, setVariants] = useState<VariantModel[]>([]);

  const getVariants = useCallback(async () => {
    try {
      setLoading(true);
      if (user) {
        const response = await fetch('https://cdn.buyjavy.com/cafcb58f5f7280959b1327dc8606de30c450e4e1213dbae22afb3004be13f498.json');
        const variantsData = await response.json();
        setVariants([
          ...Object.entries(variantsData).map(([key, value]) => ({
            // @ts-ignore
            ...value,
            id: key,
          })),
        ]);
      } else {
        setVariants([]);
      }
    } catch (error) {
      // TODO: Show some toast on error
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    getVariants();
  }, [user, getVariants]);

  return (
    <VariantsContext.Provider
      value={{
        loading,
        variants,
        getVariants,
      }}
    >
      {children}
    </VariantsContext.Provider>
  );
};
