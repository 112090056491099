import React, { useState } from 'react';
import { Badge, Box, Divider, Grid, makeStyles, useTheme } from '@material-ui/core';
import { SubscriptionModel } from '../../../../models/subscription.model';
import { Typography } from '../../../../components/typography/Typography';
import { format } from 'date-fns';
import { currencyFormatter } from '../../../../utils/currencyFormatter';
import { Button } from '../../../../components/button/Button';
import { EditItemsDialog } from './editItemsDialog/EditItemsDialog';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: `0 ${spacing(2)}px`,
  },
  card: {
    backgroundColor: '#f7f7f7',
    borderRadius: spacing(1),
    padding: spacing(3),
  },
  productImage: {
    borderRadius: spacing(1),
    overflow: 'hidden',
    width: spacing(7),
    height: spacing(7),
  },
  originalPrice: {
    textDecoration: 'line-through',
  },
  valueLabel: {
    fontSize: 24,
  },
  editButton: {
    borderRadius: 4,
    border: `2px solid #121212`,
    fontSize: 10,
    fontWeight: 700,
  },
  lineItem: {
    borderBottom: '1px solid #eee',
    padding: spacing(2, 0),
  },
  totalDivider: {
    borderTop: '1px dashed rgba(0, 0, 0, 0.2)',
  },
}));

export const Summary = ({ subscription }: { subscription: SubscriptionModel }) => {
  const {
    cartCurrency,
    currencyCart: { lineItems },
    locale,
    nextRenewal,
    renewalPeriodDays,
  } = subscription;
  const classes = useStyles();
  const theme = useTheme();
  const [isEditItemsModalOpen, setIsEditItemsModalOpen] = useState(false);

  const totalPrice = lineItems.reduce((accum, lineItem) => {
    return accum + lineItem.price * lineItem.quantity;
  }, 0);

  return (
    <div className={classes.container}>
      <Typography variant='h4' fontWeight='boldest'>
        Subscription Information
      </Typography>
      <Box height={theme.spacing(2)} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box className={classes.card} mb={4}>
            <Typography variant='caption' fontWeight='bolder' color='primary'>
              SHIPS ON
            </Typography>
            <Box height={theme.spacing(2)} />
            <Typography fontWeight='boldest' className={classes.valueLabel}>
              {format(nextRenewal, 'MMM do')}
            </Typography>
          </Box>
          <Box className={classes.card} mb={4}>
            <Typography variant='caption' fontWeight='boldest' color='primary'>
              DELIVER EVERY
            </Typography>
            <Box height={theme.spacing(2)} />
            <Typography fontWeight='boldest' className={classes.valueLabel}>
              {renewalPeriodDays} Days
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={classes.card}>
            <Typography variant='caption' fontWeight='boldest' color='primary'>
              PRODUCTS
            </Typography>
            {lineItems.map((lineItem) => (
              <Box key={lineItem.variantId} className={classes.lineItem}>
                <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item>
                        <Badge badgeContent={lineItem.quantity} color='primary'>
                          <img src={lineItem.productImage} alt='product' className={classes.productImage} />
                        </Badge>
                      </Grid>
                      <Grid item>
                        <Typography variant='h6' fontWeight='bold'>
                          {lineItem.productTitle}
                        </Typography>
                        <Typography variant='h6' color='textSecondary'>
                          Ships Every {lineItem.renewalPeriodDays} Days
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {lineItem.compareAtPrice && (
                      <Typography variant='h6' className={classes.originalPrice}>
                        {currencyFormatter(locale, cartCurrency, lineItem.compareAtPrice)}
                      </Typography>
                    )}
                    <Typography variant='h6' fontWeight='bolder'>
                      {currencyFormatter(locale, cartCurrency, lineItem.price)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Box display='flex' alignItems='center' justifyContent='space-between' mt={3}>
              <Typography variant='h6' fontWeight='medium' color='textSecondary'>
                Subtotal
              </Typography>
              <Typography variant='h6' fontWeight='bold'>
                {currencyFormatter(locale, cartCurrency, totalPrice)}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' justifyContent='space-between' mt={1}>
              <Typography variant='h6' fontWeight='medium' color='textSecondary'>
                Shipping
              </Typography>
              <Typography variant='h6' fontWeight='bold' color='textSecondary'>
                FREE SHIPPING
              </Typography>
            </Box>
            <Box mt={2}>
              <Divider variant='fullWidth' className={classes.totalDivider} />
            </Box>
            <Box display='flex' alignItems='center' justifyContent='space-between' mt={2}>
              <Box display='flex' alignItems='center'>
                <Typography variant='h4' fontWeight='boldest'>
                  Total
                </Typography>
                <Box ml={2} />
                <Typography variant='caption'>* Plus applicable Taxes</Typography>
              </Box>
              <Typography variant='h4' fontWeight='boldest'>
                {currencyFormatter(locale, cartCurrency, totalPrice)}
              </Typography>
            </Box>
            <Box mt={4}>
              <Button variant='outlined' className={classes.editButton} onClick={() => setIsEditItemsModalOpen(true)}>
                EDIT ITEMS
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isEditItemsModalOpen && (
        <EditItemsDialog isOpen={isEditItemsModalOpen} onClose={() => setIsEditItemsModalOpen(false)} subscription={subscription} />
      )}
    </div>
  );
};
