import { useAuthenticator } from '@aws-amplify/ui-react';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

interface AuthorizationProps {
  element: ReactElement;
}

export const Authorization = ({ element }: AuthorizationProps) => {
  const { user } = useAuthenticator();

  if (user) {
    return <Navigate to='/subscriptions' />;
  }

  return <>{element}</>;
};
