import { Box, Card, CircularProgress, FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import { ChangeEvent, useState } from 'react';
import { Button } from '../../../components/button/Button';
import { TextField } from '../../../components/fields/textField/TextField';
import CoffeeIcon from '../../../assets/icons/coffee.svg';
import { Typography } from '../../../components/typography/Typography';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../../hooks/useSnackbar/useSnackbar';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    backgroundColor: '#f9f9f9',
  },
  formWrapper: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    margin: 'auto',
    top: '50%',
    left: '50%',
  },
  card: {
    maxWidth: 450,
    padding: 42,
    border: '1px solid #ddd',
    boxShadow: 'none',

    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  title: {
    marginBottom: 16,
  },
  formControl: {
    display: 'block',
    marginBottom: 22,
  },
  loginButton: {
    height: 45,
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    boxShadow: 'none',
    minWidth: 280,

    [theme.breakpoints.up('sm')]: {
      minWidth: 360,
    },
  },
  forgotPasswordLabel: {
    marginBottom: 8,
  },
  actionButton: {
    height: 32,
    paddingRight: 16,
    paddingLeft: 16,
    fontSize: 9,
    border: '1px none #000',
    borderRadius: 100,
    transition: 'all 200ms cubic-bezier(.6, .04, .98, .335)',
    fontWeight: 700,

    '&:hover': {
      borderStyle: 'solid',
      color: '#5f5f5f',
      transform: 'scale(0.975)',
    },
  },
  signUpIcon: {
    marginRight: 8,
    width: 16,
    height: 16,
  },
}));

export const Signin = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [data, setData] = useState({
    email: '',
    password: '',
    newPassword: '',
  });
  const [userSession, setUserSession] = useState();
  const [loading, setLoading] = useState(false);
  const { open: openSnackbar } = useSnackbar();

  const onChange = (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [key]: event.target.value,
    });
  };

  const login = async () => {
    try {
      setLoading(true);
      const user = await Auth.signIn({
        username: data.email,
        password: data.password,
      });

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        openSnackbar({
          title: 'Update password before sign in',
          severity: 'warning',
        });

        setUserSession(user);

        return;
      }

      window.location.replace('/subscriptions');
    } catch (error) {
      openSnackbar({
        title: 'Failed to Sign In',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const updatePassword = async () => {
    try {
      setLoading(true);
      await Auth.completeNewPassword(userSession, data.newPassword);

      window.location.replace('/subscriptions');
    } catch (error) {
      openSnackbar({
        title: 'Failed to Update password. Please retry later.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.formWrapper}>
        <Card className={classes.card}>
          <Grid container className={classes.title} alignItems='center' spacing={2}>
            <Grid item>
              <img src={CoffeeIcon} width={30} alt='coffee' />
            </Grid>
            <Grid item>
              <Typography variant='h3' fontWeight='boldest'>
                Login Now!
              </Typography>
            </Grid>
          </Grid>
          {!userSession && (
            <>
              <FormControl className={classes.formControl}>
                <TextField id='email' type='email' label='Email' value={data.email} onChange={onChange('email')} fullWidth />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField id='password' type='password' label='Password' value={data.password} onChange={onChange('password')} fullWidth />
              </FormControl>
            </>
          )}
          {userSession && (
            <FormControl className={classes.formControl}>
              <TextField
                id='newPassword'
                type='password'
                label='New Password'
                value={data.newPassword}
                onChange={onChange('newPassword')}
                fullWidth
              />
            </FormControl>
          )}
          {userSession ? (
            <Button
              variant='contained'
              color='primary'
              className={classes.loginButton}
              onClick={updatePassword}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} color='secondary' thickness={4} />}
            >
              Update Password
            </Button>
          ) : (
            <Button
              variant='contained'
              color='primary'
              className={classes.loginButton}
              onClick={login}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} color='secondary' thickness={4} />}
            >
              Login
            </Button>
          )}
          <Box mt={4} display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='caption' color='textSecondary' paragraph textAlign='center' className={classes.forgotPasswordLabel}>
              Forgot Password?
            </Typography>
            <Button className={classes.actionButton} onClick={() => navigate('/forgot-password')} fullWidth>
              Reset Password Now
            </Button>
          </Box>
        </Card>
      </div>
    </div>
  );
};
