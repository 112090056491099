import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Dialog } from '../../../../../components/dialog/Dialog';
import { Box, CircularProgress } from '@material-ui/core';
import { Button } from '../../../../../components/button/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '../../../../../components/typography/Typography';
import { SubscriptionModel } from '../../../../../models/subscription.model';
import { Counter } from '../../../../../components/counter/Counter';
import OriginalImage from '../../../../../assets/imgs/original.png';
import CaramelImage from '../../../../../assets/imgs/caramel.png';
import MochaImage from '../../../../../assets/imgs/mocha.png';
import VanillaImage from '../../../../../assets/imgs/vanilla.png';
import DecafImage from '../../../../../assets/imgs/decaf.jpeg';
import PumpkinImage from '../../../../../assets/imgs/pumpkin.jpeg';
import { PRICING_TABLE } from '../../../../../constants';
import { API } from 'aws-amplify';
import { useSnackbar } from '../../../../../hooks/useSnackbar/useSnackbar';
import { SubscriptionsContext } from '../../../../../contexts/subscriptions/SubscriptionsContext';
import { VariantsContext } from '../../../../../contexts/variants/VariantsContext';
import { currencyFormatter } from '../../../../../utils/currencyFormatter';
import { FlavorItemMetaBox } from './FlavorItemMetaBox';

enum Flavor {
  original = 'original',
  caramel = 'caramel',
  vanilla = 'vanilla',
  mocha = 'mocha',
  decaf = 'decaf',
  pumpkin = 'pumpkin',
}

interface ICount {
  [Flavor.original]: number;
  [Flavor.caramel]: number;
  [Flavor.vanilla]: number;
  [Flavor.mocha]: number;
  [Flavor.decaf]: number;
  [Flavor.pumpkin]: number;
}

const useStyles = makeStyles(({ spacing }) => ({
  cancelButton: {
    fontSize: 10,
    marginTop: spacing(1),
  },
  variant: {
    height: spacing(8),
    border: '1px solid #efefef',
    borderRadius: spacing(1),
    overflow: 'hidden',
  },
}));

export const EditItemsDialog = ({
  isOpen,
  onClose,
  subscription,
}: {
  isOpen: boolean;
  onClose: () => void;
  subscription: SubscriptionModel;
}) => {
  const { id, cartCurrency, shippingZone, locale } = subscription;
  const { updateSubscription } = useContext(SubscriptionsContext);
  const { variants } = useContext(VariantsContext);
  const { open: openSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isUpdating, setIsUpdating] = useState(false);
  const [counts, setCounts] = useState<ICount>({
    original: 0,
    caramel: 0,
    vanilla: 0,
    mocha: 0,
    decaf: 0,
    pumpkin: 0,
  });

  const getVariantData = useCallback(
    (variantId: number) => {
      const variant = variants?.find((v) => v.id === `${variantId}`);

      if (!variant) return null;

      const data: any = Object.assign({}, variant, variant.presentmentPrices[cartCurrency]);
      delete data.presentmentPrices;
      data.shippingRate = 0;
      data.shippingRateFormatted = '';

      try {
        data.shippingRate = data.shippingRates[shippingZone || 'DEFAULT'] || 0;
        data.shippingRateFormatted = currencyFormatter(locale, cartCurrency, data.shippingRate);
      } catch (err) {
        console.log(err);
      }
      delete data.shippingRates;

      ['price', 'compareAtPrice', 'compareSavings'].forEach((field) => {
        const num = parseFloat(data[field]);
        if (num && !isNaN(num)) {
          data[field + 'Formatted'] = currencyFormatter(locale, cartCurrency, num);
        }
      });

      return data;
    },
    [cartCurrency, locale, shippingZone, variants]
  );

  const totalBottles = useMemo(
    () =>
      Math.min(
        4,
        Object.values(counts).reduce((accum, count) => accum + count, 0)
      ),
    [counts]
  );

  const handleClose = () => {
    if (isUpdating) return;

    onClose();
  };

  const handleUpdateSubscriptionItems = async () => {
    setIsUpdating(true);

    try {
      const totalBottles = Math.min(
        4,
        Object.values(counts).reduce((accum, count) => accum + count, 0)
      );

      const response = await API.put('subscriber', `/subscriptions/${id}`, {
        body: {
          lineItems: Object.entries(counts)
            .filter(([flavor, quantity]) => quantity > 0)
            .map(([flavor, quantity]) => ({
              // @ts-ignore
              variantId: PRICING_TABLE[totalBottles][flavor],
              quantity,
            })),
        },
      });

      openSnackbar({
        title: 'Updated Subscription Items',
        severity: 'success',
      });

      if (response.subscription && updateSubscription) {
        updateSubscription(response.subscription);
      }

      onClose();
    } catch (error) {
      openSnackbar({
        title: 'Update Subscription Items Failed',
        severity: 'error',
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const variantSelectors = useMemo(
    () => [
      {
        label: 'Original',
        type: Flavor.original,
        image: OriginalImage,
      },
      {
        label: 'Caramel',
        type: Flavor.caramel,
        image: CaramelImage,
      },
      {
        label: 'French Vanilla',
        type: Flavor.vanilla,
        image: VanillaImage,
      },
      {
        label: 'Mocha',
        type: Flavor.mocha,
        image: MochaImage,
      },
      {
        label: 'Decaf',
        type: Flavor.decaf,
        image: DecafImage,
      },
      {
        label: 'Pumpkin',
        type: Flavor.pumpkin,
        image: PumpkinImage,
      },
    ],
    []
  );

  const metaDataVariant = useMemo(() => {
    if (totalBottles === 0) return null;

    return variantSelectors.find((variant) => counts[variant.type] > 0)?.type;
  }, [counts, totalBottles, variantSelectors]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title='Edit Items'
      actionButtonsDirection='column'
      actionButtons={
        <Box>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleUpdateSubscriptionItems}
            startIcon={isUpdating && <CircularProgress size={20} color='secondary' thickness={4} />}
            disabled={isUpdating}
          >
            UPDATE SUBSCRIPTION ITEMS
          </Button>
          <Button variant='text' fullWidth onClick={handleClose} disabled={isUpdating} className={classes.cancelButton}>
            CANCEL
          </Button>
        </Box>
      }
    >
      {variantSelectors.map((variant, variantIndex) => (
        <Box key={variant.type} mb={variantSelectors.length === variantIndex + 1 ? 0 : 2}>
          <Box
            key={variant.type}
            width='100%'
            display='flex'
            flexDirection='row'
            alignItems='center'
            className={classes.variant}
            flexShrink={0}
            mb={1}
          >
            <img src={variant.image} alt='product' height={64} width={64} />
            <Box ml={2} flexGrow={1}>
              <Typography variant='h6' fontWeight='bold'>
                {variant.label}
              </Typography>
            </Box>
            <Counter onUpdateCount={(count) => setCounts({ ...counts, [variant.type]: count })} count={counts[variant.type]} />
          </Box>
        </Box>
      ))}
      {totalBottles > 0 && (
        <FlavorItemMetaBox
          data={
            // @ts-ignore
            getVariantData(PRICING_TABLE[totalBottles][metaDataVariant])
          }
        />
      )}
    </Dialog>
  );
};
