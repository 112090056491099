import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PackageIcon = (props: SvgIconProps) => (
  <SvgIcon x='0px' y='0px' viewBox='0 0 49.46 52.97' {...props}>
    <style type='text/css'>{`.package-st0{fill:none;stroke:${
      props.stroke || '#121212'
    };stroke-width:2;stroke-miterlimit:10;}.package-st1{fill:none;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}`}</style>
    <g>
      <polygon className='package-st0' points='0.99,40.22 24.1,51.36 24.58,24.71 0.99,13.36 	' />
      <line className='package-st0' x1='34.13' y1='19.44' x2='24.58' y2='24.71' />
      <path className='package-st0' d='M34.19,19.77' />
      <polyline className='package-st0' points='0.44,12.68 24.58,1.18 47.9,12.91 47.64,13.26 39.51,17.07 	' />
      <polyline className='package-st0' points='24.58,52.01 48.16,40.19 48.43,12.52 	' />
      <polyline className='package-st0' points='16.92,5.27 39.51,17.07 39.51,26.52 34.2,29.14 34.13,19.44 10.12,8.3 	' />
      <path className='package-st1' d='M12.49,27.14L9.12,37.37c0,0,0,2.59,3.31,2.71s3.31-2.71,3.31-2.71L12.49,27.14z' />
    </g>
  </SvgIcon>
);
