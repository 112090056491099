import { useAuthenticator } from '@aws-amplify/ui-react';
import { createContext, ReactElement, useCallback, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { SubscriptionModel } from '../../models/subscription.model';

interface SubscriptionsContextType {
  loading?: boolean;
  subscriptions?: SubscriptionModel[];
  getSubscriptions?: () => Promise<void>;
  updateSubscription?: (subscription: SubscriptionModel) => void;
}

export const SubscriptionsContext = createContext<SubscriptionsContextType>({});

interface SubscriptionsProviderProps {
  children: ReactElement;
}

export const SubscriptionsProvider = ({ children }: SubscriptionsProviderProps) => {
  const { user } = useAuthenticator();
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<SubscriptionModel[]>([]);

  const getSubscriptions = useCallback(async () => {
    try {
      setLoading(true);
      if (user) {
        const response = await API.get('subscriber', '/subscriptions', {});
        setSubscriptions(response.subscriptions);
      } else {
        setSubscriptions([]);
      }
    } catch (error) {
      // TODO: Show some toast on error
    } finally {
      setLoading(false);
    }
  }, [user]);

  const updateSubscription = useCallback(
    async (newSubscription: SubscriptionModel) => {
      setSubscriptions([...subscriptions.map((subscription) => (subscription.id === newSubscription.id ? newSubscription : subscription))]);
    },
    [subscriptions]
  );

  useEffect(() => {
    getSubscriptions();
  }, [user, getSubscriptions]);

  return (
    <SubscriptionsContext.Provider
      value={{
        loading,
        subscriptions,
        getSubscriptions,
        updateSubscription,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};
