import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LogoIcon = (props: SvgIconProps) => (
  <SvgIcon x='0px' y='0px' viewBox='0 0 104.5 35' {...props}>
    <style type='text/css'>{`.logo_st0{fill:#FCDF2F;}.logo_st1{fill:#020200;}`}</style>
    <circle className='logo_st0' cx='17.5' cy='17.5' r='17.5' />
    <g id='Group_392' transform='translate(-30 -114.871)'>
      <g id='javy-logo' transform='translate(71.193 123.871)'>
        <path
          id='Path_32'
          d='M0,9.7h5.7c0,1.2,0.4,1.7,1.2,1.7c0.9,0,1.2-0.6,1.2-1.5V0h5.7v9.2c0,5-2.8,6.9-7,6.9C2.1,16.1,0,13.2,0,9.7
			z'
        />
        <path id='Path_34' d='M28.8,0h6.1l2.5,9.9L39.9,0H46l-5.4,15.7h-6.4L28.8,0z' />
        <path id='Path_35' d='M63.3,0l-7.8,15.7h-6.4c1.6-3.3,1.6-7.1,0.1-10.3L46.6,0h6.7L55,5l1.9-5H63.3z' />
      </g>
      <path
        id='Path_33'
        d='M96.1,137.8h-4.6l-0.4,1.7h-5.9l5.4-15.7H97l5.4,15.7h-5.9L96.1,137.8z M94.8,132.6l-1-3.9l-1,3.9
		c-0.1,0.5,0.2,1.1,0.7,1.2c0.1,0,0.2,0,0.2,0l0,0c0.6,0,1-0.5,1-1C94.8,132.8,94.8,132.7,94.8,132.6z'
      />
    </g>
    <path
      className='logo_st1'
      d='M17.5,6.3c0.4,1.6,0.8,3.1,1.1,4.5c1,4,2,8,3,12.1c0.7,2.8-1.5,5.4-4.4,5.3c-2.6-0.1-4.5-2.6-3.9-5.2
	C14.7,17.4,16.1,12,17.5,6.3z'
    />
  </SvgIcon>
);
