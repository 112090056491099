export const PRICING_TABLE = {
  1: {
    original: 41411703242913,
    caramel: 41411703275681,
    vanilla: 41411703308449,
    pumpkin: 41411703341217,
    mocha: 41411703373985,
    decaf: 41411703439521,
  },
  2: {
    original: 41411705274529,
    caramel: 41411705372833,
    vanilla: 41411705503905,
    pumpkin: 41411705602209,
    mocha: 41411705667745,
    decaf: 41411705733281,
  },
  3: {
    original: 41411707830433,
    caramel: 41411707863201,
    vanilla: 41411707895969,
    pumpkin: 41411707928737,
    mocha: 41411707961505,
    decaf: 41411707994273,
  },
  4: {
    original: 41411710353569,
    caramel: 41411710386337,
    vanilla: 41411710419105,
    pumpkin: 41411710451873,
    mocha: 41411710484641,
    decaf: 41411710517409,
  },
};
