import TextFieldBaseComponent, { TextFieldProps as TextFieldBaseProps } from '@material-ui/core/TextField';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  input: {
    height: 44,
  },
  inputOutline: {
    height: 7,
  },
  inputLabel: {
    fontSize: 11,
    color: '#000',

    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -4px) scale(0.75)',
    },
  },
});

type TextFieldProps = TextFieldBaseProps & {};

export const TextField = ({ variant = 'outlined', className, InputLabelProps, InputProps, ...props }: TextFieldProps) => {
  const classes = useStyles();

  return (
    <TextFieldBaseComponent
      variant={variant}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
        classes: {
          ...InputLabelProps?.classes,
          root: clsx(classes.inputLabel, InputLabelProps?.classes?.root),
        },
      }}
      className={clsx(classes.input, className)}
      InputProps={{
        ...InputProps,
        className: clsx(classes.input, InputProps?.className),
        classes: {
          ...InputProps?.classes,
          input: clsx(classes.inputOutline, InputProps?.classes?.input),
        },
      }}
      {...props}
    />
  );
};
