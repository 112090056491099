import { Divider, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Button } from '../../../components/button/Button';
import { SubscriptionModel } from '../../../models/subscription.model';

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    marginBottom: spacing(3),
  },
  button: {
    border: '1px solid #e7e7e7',
    backgroundColor: 'transparent',
    padding: `0 ${spacing(4)}px`,
    height: spacing(6),
    textTransform: 'none',
    fontSize: '14px !important',
    fontWeight: 900,

    '&.active': {
      backgroundColor: palette.common.white,
    },
  },
  divider: {
    margin: `0 ${spacing(1)}px`,
    height: spacing(3),
  },
}));

interface SubscriptionsHeaderProps {
  subscriptions?: SubscriptionModel[];
  selectedSubscriptionIndex?: number;
  onSelectSubscription: (subscriptionIndex: number) => void;
}

export const SubscriptionsHeader = ({ subscriptions = [], selectedSubscriptionIndex, onSelectSubscription }: SubscriptionsHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        {subscriptions?.map((subscription, index) => (
          <Grid key={index} item>
            <Button
              className={clsx(classes.button, selectedSubscriptionIndex === index ? 'active' : '')}
              onClick={() => onSelectSubscription(index)}
            >
              Subscription #{index + 1}
              <Divider orientation='vertical' className={classes.divider} />
              {format(subscription.nextRenewal, 'MMM do')}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
