import { CardContent, CardHeader, Grid, makeStyles } from '@material-ui/core';
import { Card } from '../../../components/card/Card';
import { Button } from '../../../components/button/Button';
import { fontWeight } from '../../../theme/typography';
import { PackageFilledIcon } from '../../../components/icons/PackageFilledIcon';
import { useContext, useState } from 'react';
import { Summary } from './summary/Summary';
import { Typography } from '../../../components/typography/Typography';
import { ManageSubscription } from './manageSubscription/ManageSubscription';
import { SubscriptionsContext } from '../../../contexts/subscriptions/SubscriptionsContext';

interface SubscriptionDetailsCardProps {
  subscriptionIndex: number;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: `${spacing(8)}px ${spacing(2)}px ${spacing(2)}px`,
  },
  header: {
    padding: `0 ${spacing(2)}px`,
  },
  name: {
    fontSize: spacing(4),
  },
  trackButton: {
    fontSize: 10,
    fontWeight: fontWeight.bolder,
  },
  chipGreen: {
    backgroundColor: '#c7f5da',
    borderRadius: 3,
    padding: '1px 7px',
  },
  chipLabel: {
    color: '#1c5835',
    padding: 0,
    fontSize: 11,
    fontWeight: fontWeight.bolder,
    lineHeight: '14px',
  },
  tabButton: {
    textTransform: 'none',
    padding: `${spacing(1)}px ${spacing(4)}px`,
    fontWeight: 500,

    '&.MuiButton-outlined': {
      borderColor: 'rgba(18, 18, 18, 0.16)',
      borderWidth: 1,
    },
  },
  packageIcon: {
    width: 42,
    height: 42,
  },
  content: {
    padding: `${spacing(5)}px 0 0`,

    '&:last-child': {
      paddingBottom: 0,
    },
  },
  contentItem: {
    padding: `0 ${spacing(2)}px`,
  },
  divider: {
    margin: `${spacing(4)}px 0`,
    borderTop: '1px dashed #cccbcb',
  },
}));

export const SubscriptionDetailsCard = ({ subscriptionIndex }: SubscriptionDetailsCardProps) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const { subscriptions } = useContext(SubscriptionsContext);

  const selectedSubscription = subscriptions?.[subscriptionIndex];

  if (!selectedSubscription) return null;

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        title={
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <PackageFilledIcon className={classes.packageIcon} />
                </Grid>
                <Grid item>
                  <Typography variant='h1' fontWeight='boldest'>
                    Subscription #{subscriptionIndex + 1}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/*<Grid item>*/}
            {/*  <Grid container alignItems='center' spacing={1}>*/}
            {/*    <Grid item>*/}
            {/*      <Typography variant='caption' fontWeight='medium'>*/}
            {/*        Previous Shipment Delivered:*/}
            {/*      </Typography>*/}
            {/*    </Grid>*/}
            {/*    <Grid item>*/}
            {/*      <Chip label='FEB 10th' className={classes.chipGreen} classes={{ label: classes.chipLabel }} />*/}
            {/*    </Grid>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </Grid>
        }
      />
      <CardContent className={classes.content}>
        <Grid container spacing={2} className={classes.contentItem}>
          <Grid item xs={12} sm={'auto'}>
            <Button
              variant={selectedTab === 0 ? 'contained' : 'outlined'}
              color={selectedTab === 0 ? 'primary' : 'inherit'}
              className={classes.tabButton}
              onClick={() => setSelectedTab(0)}
              fullWidth
            >
              Summary
            </Button>
          </Grid>
          <Grid item xs={12} sm={'auto'}>
            <Button
              variant={selectedTab === 1 ? 'contained' : 'outlined'}
              color={selectedTab === 1 ? 'primary' : 'inherit'}
              className={classes.tabButton}
              onClick={() => setSelectedTab(1)}
              fullWidth
            >
              Manage Subscription
            </Button>
          </Grid>
        </Grid>
        <div className={classes.divider} />
        {selectedTab === 0 && <Summary subscription={selectedSubscription} />}
        {selectedTab === 1 && <ManageSubscription subscription={selectedSubscription} />}
      </CardContent>
    </Card>
  );
};
