import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { ReactElement } from 'react';
import { typography } from './typography';
import { palette } from './palette';

const theme = createTheme({
  palette: palette,
  typography: {
    ...typography,
    fontFamily: 'Gotham Pro, sans-serif',
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: palette.common?.black,

        '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
          fontSize: 11,
          fontWeight: 500,
        },
      },
    },
    MuiBadge: {
      badge: {
        minWidth: 15,
        fontSize: 11,
        height: 15,
      },
    },
  },
});

export const Theme = ({ children }: { children: ReactElement }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
