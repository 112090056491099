import { Box } from '@material-ui/core';
import { Button } from '../button/Button';
import { Typography } from '../typography/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  counterButton: {
    width: 36,
    height: 36,
    borderRadius: 18,
    border: '1px solid #efefef',
    minWidth: 36,
    margin: 16,
    fontSize: '18px !important',
  },
});

interface CounterProps {
  onUpdateCount: (count: number) => void;
  count: number;
}

export const Counter = ({ onUpdateCount, count = 0 }: CounterProps) => {
  const classes = useStyles();

  return (
    <Box display='flex' alignItems='center' flexDirection='row'>
      <Button className={classes.counterButton} onClick={() => onUpdateCount(Math.max(0, count - 1))}>
        -
      </Button>
      <Typography fontWeight='bold'>{count}</Typography>
      <Button className={classes.counterButton} onClick={() => onUpdateCount(count + 1)}>
        +
      </Button>
    </Box>
  );
};
