export const fontWeight = {
  regular: 400,
  medium: 500,
  bold: 600,
  bolder: 700,
  boldest: 900,
};

export const typography = {
  h1: {
    fontSize: 32,
    letterSpacing: '-0.5px',
    lineHeight: '38px',
  },
  h3: {
    fontSize: 28,
    letterSpacing: '-0.5px',
    lineHeight: '36px',
  },
  h4: {
    fontSize: 18,
  },
  h5: {
    fontSize: 16,
  },
  h6: {
    fontSize: 14,
  },
  caption: {
    fontSize: 12,
  },
};
