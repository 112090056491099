import { Card, CircularProgress, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '../../../components/typography/Typography';
import { TextField } from '../../../components/fields/textField/TextField';
import { Button } from '../../../components/button/Button';
import { ChangeEvent, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { useSnackbar } from '../../../hooks/useSnackbar/useSnackbar';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    backgroundColor: '#f9f9f9',
  },
  formWrapper: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    margin: 'auto',
    top: '50%',
    left: '50%',
  },
  card: {
    maxWidth: 450,
    padding: 42,
    border: '1px solid #ddd',
    boxShadow: 'none',

    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  title: {
    marginBottom: 48,
  },
  formControl: {
    display: 'block',
    marginBottom: 22,
  },
  sendVerificationEmailButton: {
    height: 45,
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    width: '100%',
    boxShadow: 'none',
    minWidth: 280,

    [theme.breakpoints.up('sm')]: {
      minWidth: 360,
    },
  },
  actionButton: {
    height: 32,
    paddingRight: 16,
    paddingLeft: 16,
    fontSize: 9,
    border: '1px none #000',
    borderRadius: 100,
    transition: 'all 200ms cubic-bezier(.6, .04, .98, .335)',
    fontWeight: 700,

    '&:hover': {
      borderStyle: 'solid',
      color: '#5f5f5f',
      transform: 'scale(0.975)',
    },
  },
  signUpIcon: {
    marginRight: 8,
    width: 16,
    height: 16,
  },
}));

export const ForgotPassword = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { open: openSnackbar } = useSnackbar();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSendVerificationEmail = async () => {
    try {
      setLoading(true);
      await API.post('subscriber', '/forgot_password', { body: { email } });

      await Auth.forgotPassword(email);
      openSnackbar({
        title: 'Reset Password Request Sent',
        severity: 'success',
      });
    } catch (error) {
      // @ts-ignore
      if (error.code === 'UserNotFoundException') {
        openSnackbar({
          title: 'User not found. Please contact support',
          severity: 'error',
        });
      } else {
        openSnackbar({
          title: 'Failed to Send Reset Password request',
          severity: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.formWrapper}>
        <Card className={classes.card}>
          <Typography variant='h3' fontWeight='boldest' className={classes.title}>
            Forgot Password?
          </Typography>
          <FormControl className={classes.formControl}>
            <TextField
              id='email'
              type='email'
              label='Email'
              placeholder='e.g. myaddress@email.com'
              value={email}
              onChange={onChange}
              fullWidth
            />
          </FormControl>
          <Button
            variant='contained'
            color='primary'
            className={classes.sendVerificationEmailButton}
            onClick={handleSendVerificationEmail}
            disabled={loading || email === ''}
            startIcon={loading && <CircularProgress size={20} color='secondary' thickness={4} />}
          >
            Send Verification Email
          </Button>
        </Card>
      </div>
    </div>
  );
};
