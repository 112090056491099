import { ChangeEvent, useContext, useState } from 'react';
import { API } from 'aws-amplify';
import { CircularProgress, FormControl, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../../../../../components/button/Button';
import { TextField } from '../../../../../components/fields/textField/TextField';
import { SubscriptionModel } from '../../../../../models/subscription.model';
import { SubscriptionsContext } from '../../../../../contexts/subscriptions/SubscriptionsContext';
import { AddressModel } from '../../../../../models/address.model';
import { Dialog } from '../../../../../components/dialog/Dialog';
import { useSnackbar } from '../../../../../hooks/useSnackbar/useSnackbar';

const useStyles = makeStyles({
  formControl: {
    display: 'block',
    marginBottom: 22,
  },
});

export const EditShippingAddressDialog = ({
  isOpen,
  onClose,
  subscription,
}: {
  isOpen: boolean;
  onClose: () => void;
  subscription: SubscriptionModel;
}) => {
  const { open: openSnackbar } = useSnackbar();
  const { updateSubscription } = useContext(SubscriptionsContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const classes = useStyles();
  const [formData, setFormData] = useState<AddressModel>(subscription.shippingAddress);

  const handleChangeField = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const handleClose = () => {
    if (isUpdating) return;

    onClose();
  };

  const handleUpdateAddress = async () => {
    setIsUpdating(true);

    try {
      const response = await API.put('subscriber', `/subscriptions/${subscription.id}`, {
        body: {
          shippingAddress: formData,
        },
      });

      if (response.subscription && updateSubscription) {
        updateSubscription(response.subscription);
      }

      openSnackbar({
        title: 'Address Updated',
        severity: 'success',
      });
    } catch (error) {
      openSnackbar({
        title: 'Update Address Failed',
        severity: 'error',
      });
    } finally {
      setIsUpdating(false);
      handleClose();
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title='Edit Address'
      actionButtons={
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Button variant='outlined' color='primary' onClick={handleClose} fullWidth>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={7}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleUpdateAddress}
              disabled={isUpdating}
              startIcon={isUpdating && <CircularProgress size={20} color='secondary' thickness={4} />}
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <TextField
              id='firstname'
              name='first_name'
              label='First Name'
              value={formData.first_name}
              onChange={handleChangeField('first_name')}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <TextField
              id='lastname'
              name='last_name'
              label='Last Name'
              value={formData.last_name}
              onChange={handleChangeField('last_name')}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              id='address1'
              name='address1'
              label='Address 1'
              value={formData.address1}
              onChange={handleChangeField('address1')}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <TextField
              id='address2'
              name='address2'
              label='Address 2 (optional)'
              value={formData.address2}
              onChange={handleChangeField('address2')}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <TextField
              id='province'
              name='province'
              label='State'
              value={formData.province}
              onChange={handleChangeField('province')}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl className={classes.formControl}>
            <TextField
              id='zip'
              name='zip'
              label='Zip code'
              type='number'
              placeholder='e.g. 28532'
              value={formData.zip}
              onChange={handleChangeField('zip')}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl className={classes.formControl}>
            <TextField
              id='city'
              name='city'
              label='City'
              placeholder='e.g. New York City'
              value={formData.city}
              onChange={handleChangeField('city')}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              id='phone'
              name='phone'
              label='Phone Number'
              value={formData.phone}
              onChange={handleChangeField('phone')}
              fullWidth
              placeholder='e.g. (513) 642-5869'
            />
          </FormControl>
        </Grid>
      </Grid>
    </Dialog>
  );
};
