import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const primary = '#c8202f';
const primaryLight = '#c8202fcc';
const black = '#121212';

export const palette: PaletteOptions = {
  primary: {
    main: primary,
    light: primaryLight,
  },
  common: {
    black,
  },
};
