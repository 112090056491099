import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const LogoutIcon = (props: SvgIconProps) => (
  <SvgIcon x='0px' y='0px' viewBox='0 0 52.4 52.7' {...props}>
    <style type='text/css'>{`.logout-st0{fill:none;stroke:${
      props.stroke || '#121212'
    };stroke-width:2;stroke-miterlimit:10;}.logout-st1{fill:none;stroke:${
      props.stroke || '#121212'
    };stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}.logout-st2{fill:#FAFAFC;stroke:${
      props.stroke || '#121212'
    };stroke-width:2;stroke-miterlimit:10;}`}</style>
    <polyline className='logout-st0' points='1.2,1 31.7,1 31.7,45.9 22.2,45.9 ' />
    <line className='logout-st1' x1='50.7' y1='24' x2='36.2' y2='24' />
    <polyline className='logout-st1' points='44.3,18.4 50.7,24 44.3,29.2 ' />
    <polygon className='logout-st2' points='1.2,1 21.9,6.9 22.2,7.2 21.9,51.5 21.5,51.7 1.5,45.3 1.2,44.9 0.9,1.2 1.2,1 ' />
    <circle className='logout-st2' cx='15.4' cy='27.4' r='2' />
  </SvgIcon>
);
