import { makeStyles } from '@material-ui/core/styles';
import ButtonBaseComponent, { ButtonProps as ButtonBaseProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import { fontWeight } from '../../theme/typography';

type ButtonProps = ButtonBaseProps & {};

const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
    fontWeight: fontWeight.bolder,
    textDecoration: ({ variant }: { variant?: 'text' | 'outlined' | 'contained' }) => (variant === 'text' ? 'underline' : 'inherit'),
    borderColor: '#000',
    borderWidth: 2,

    '&.MuiButton-containedPrimary': {
      padding: '0 32px',
      height: 44,
      borderWidth: 2,
    },

    '&.MuiButton-outlinedPrimary': {
      color: '#000',
      padding: '0 32px',
      height: 44,
    },

    '&.MuiButton-text': {
      color: '#000',
      fontSize: 10,
    },
  },
});

export const Button = ({ children, className, variant, ...props }: ButtonProps) => {
  const classes = useStyles({ variant });

  return (
    <ButtonBaseComponent variant={variant} className={clsx(classes.root, className)} {...props}>
      {children}
    </ButtonBaseComponent>
  );
};
