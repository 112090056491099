import { Authenticator } from '@aws-amplify/ui-react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Amplify, API } from 'aws-amplify';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';
import { SubscriptionsProvider } from './contexts/subscriptions/SubscriptionsContext';
import { AppRoutes } from './routing/AppRoutes';
import { Theme } from './theme/Theme';
import { Snackbar } from './components/snackbar/Snackbar';
import { SnackbarProvider } from './contexts/snackbar/SnackbarContext';
import { VariantsProvider } from './contexts/variants/VariantsContext';

window.LOG_LEVEL = 'DEBUG';

declare global {
  interface Window {
    Amplify: any;
    API: any;
    jwt_rest: any;
    tmp: any;
    LOG_LEVEL: string;
  }
}

const App: React.FunctionComponent = () => {
  const [amplifyReady, setAmplifyReady] = React.useState<boolean>(false);

  const configAmplify = async () => {
    let apiEndpoint = null;
    if (window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost') {
      apiEndpoint = 'https://fpz7vixdp0.execute-api.us-east-1.amazonaws/v1';
    } else {
      apiEndpoint = window.location.origin + '/v1';
    }
    console.log('apiEndpoint', apiEndpoint);
    const data = await window.fetch(`${apiEndpoint}/meta`);
    const meta = await data.json();

    Amplify.configure({
      Auth: {
        region: 'us-east-1',
        identityPoolId: meta.identityPoolId,
        userPoolId: meta.userPoolId,
        userPoolWebClientId: meta.userPoolClientId, // actually the user pool client id.,
      },
      API: {
        endpoints: [{ name: 'subscriber', endpoint: `https://${meta.apiGateway}/v1` }],
      },
    });
    console.log(Amplify, API);
    setAmplifyReady(true);
    window.Amplify = Amplify;
    window.API = API;
    console.log(meta);
  };

  React.useEffect(() => {
    if (!amplifyReady) {
      configAmplify();
    }
  }, [amplifyReady]);

  return !amplifyReady ? null : <AuthStateApp />;
};

const AuthStateApp: React.FunctionComponent = () => {
  return (
    <Authenticator.Provider>
      <Theme>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider>
            <SubscriptionsProvider>
              <VariantsProvider>
                <Router>
                  <AppRoutes />
                  <Snackbar />
                </Router>
              </VariantsProvider>
            </SubscriptionsProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </Theme>
    </Authenticator.Provider>
  );
};

export default App;
