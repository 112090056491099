import { useContext } from 'react';
import { SnackbarContext, SnackbarStateProps } from '../../contexts/snackbar/SnackbarContext';

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  return {
    open: (props: SnackbarStateProps) => context?.setSnackbarState({ isOpen: true, props }),
  };
};
