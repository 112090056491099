import { makeStyles } from '@material-ui/core';
import TypographyBaseComponent, { TypographyProps as TypographyBaseComponentProps } from '@material-ui/core/Typography';
import { fontWeight } from '../../theme/typography';

interface TypographyProps extends TypographyBaseComponentProps {
  fontWeight?: 'regular' | 'medium' | 'bold' | 'bolder' | 'boldest';
  textAlign?: 'inherit' | 'center' | 'left' | 'right';
}

const useStyles = makeStyles({
  root: {
    fontWeight: ({
      weight,
    }: {
      weight?: 'regular' | 'medium' | 'bold' | 'bolder' | 'boldest';
      textAlign?: 'inherit' | 'center' | 'left' | 'right';
    }) => fontWeight[weight || 'regular'],
    textAlign: ({
      textAlign,
    }: {
      weight?: 'regular' | 'medium' | 'bold' | 'bolder' | 'boldest';
      textAlign?: 'inherit' | 'center' | 'left' | 'right';
    }) => textAlign,
  },
});

export const Typography = ({ children, fontWeight, textAlign = 'inherit', ...props }: TypographyProps) => {
  const classes = useStyles({ weight: fontWeight, textAlign });

  return (
    <TypographyBaseComponent classes={{ root: classes.root }} {...props}>
      {children}
    </TypographyBaseComponent>
  );
};
