import { useAuthenticator } from '@aws-amplify/ui-react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { Drawer } from '../../components/drawer/Drawer';

interface DashboardProps {
  element: ReactElement;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    content: {
      flexGrow: 1,
      padding: 42,
      backgroundColor: '#f9f9f9',
      minHeight: '100vh',
      marginTop: 64,

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        marginTop: 0,
      },
    },
  })
);

export const Dashboard = ({ element }: DashboardProps): JSX.Element => {
  const classes = useStyles();
  const { user } = useAuthenticator();

  if (!user) {
    return <Navigate to='/signin' />;
  }

  return (
    <div className={classes.root}>
      <Drawer />
      <main className={classes.content}>{element}</main>
    </div>
  );
};
