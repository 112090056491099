import { Dialog as DialogBase, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { Typography } from '../typography/Typography';
import { fontWeight } from '../../theme/typography';
import { ReactNode } from 'react';

const useStyles = makeStyles({
  titleContainer: {
    padding: 32,
    borderBottom: '1px dashed #d6d6d6',
  },
  title: {
    fontSize: 24,
    lineHeight: '130%',
    letterSpacing: -0.5,
    fontWeight: fontWeight.boldest,
  },
  content: {
    padding: 32,
    paddingBottom: 0,
  },
  actions: {
    padding: 32,
    flexDirection: ({ actionButtonsDirection }: { actionButtonsDirection?: 'row' | 'column' }) => actionButtonsDirection,

    '&.MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: ({ actionButtonsDirection }: { actionButtonsDirection?: 'row' | 'column' }) =>
        actionButtonsDirection === 'row' ? 16 : 0,
      marginTop: ({ actionButtonsDirection }: { actionButtonsDirection?: 'row' | 'column' }) => (actionButtonsDirection === 'row' ? 0 : 16),
    },
  },
});

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  title: string;
  children: ReactNode;
  actionButtonsDirection?: 'row' | 'column';
  actionButtons: ReactNode;
};

export const Dialog = ({ isOpen, onClose, maxWidth, title, children, actionButtonsDirection = 'row', actionButtons }: DialogProps) => {
  const classes = useStyles({ actionButtonsDirection });

  return (
    <DialogBase open={isOpen} onClose={onClose} maxWidth={maxWidth} style={{ zIndex: 1500 }}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
      <DialogActions className={classes.actions}>{actionButtons}</DialogActions>
    </DialogBase>
  );
};
