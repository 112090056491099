import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Button } from '../../../../../components/button/Button';
import { SubscriptionModel } from '../../../../../models/subscription.model';
import { useContext, useState } from 'react';
import { SubscriptionsContext } from '../../../../../contexts/subscriptions/SubscriptionsContext';
import { API } from 'aws-amplify';
import { Dialog } from '../../../../../components/dialog/Dialog';
import { Typography } from '../../../../../components/typography/Typography';
import { fontWeight } from '../../../../../theme/typography';
import { useSnackbar } from '../../../../../hooks/useSnackbar/useSnackbar';

const useStyles = makeStyles({
  frequencyButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  frequency: {
    width: 72,
    height: 72,
    borderRadius: 36,
    border: '2px solid #000',
    marginBottom: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: fontWeight.boldest,

    '&.MuiButton-text': {
      fontSize: 28,
    },

    '&.selected': {
      color: '#fff',
      backgroundColor: '#000',
    },

    '&:hover': {
      color: '#000',
      backgroundColor: '#fae9ea',
    },
  },
});

export const EditFrequencyDialog = ({
  isOpen,
  onClose,
  subscription,
}: {
  isOpen: boolean;
  onClose: () => void;
  subscription: SubscriptionModel;
}) => {
  const { open: openSnackbar } = useSnackbar();
  const classes = useStyles();
  const { updateSubscription } = useContext(SubscriptionsContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [frequency, setFrequency] = useState<number>(subscription.renewalPeriodDays);

  const handleChangeField = (value: number) => {
    setFrequency(value);
  };

  const handleClose = () => {
    if (isUpdating) return;

    onClose();
  };

  const handleUpdateFrequency = async () => {
    setIsUpdating(true);

    try {
      const response = await API.put('subscriber', `/subscriptions/${subscription.id}`, {
        body: {
          renewalPeriodDays: Math.floor(frequency),
        },
      });

      if (response.subscription && updateSubscription) {
        updateSubscription(response.subscription);
      }

      openSnackbar({
        title: 'Frequency Updated Successfully',
        severity: 'success',
      });
    } catch (error) {
      openSnackbar({
        title: 'Frequency Update Failed',
        severity: 'error',
      });
    } finally {
      setIsUpdating(false);
      handleClose();
    }
  };

  const frequencyItems = [15, 30, 45, 60, 90];

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title='Choose Frequency'
        actionButtons={
          <>
            <Button
              variant='contained'
              color='primary'
              onClick={handleUpdateFrequency}
              disabled={isUpdating || Math.floor(frequency) < 14 || Math.floor(frequency) > 180}
              startIcon={isUpdating && <CircularProgress size={20} color='secondary' thickness={4} />}
              fullWidth
            >
              Save Changes
            </Button>
            <Button variant='text' onClick={handleClose} fullWidth>
              Cancel
            </Button>
          </>
        }
        actionButtonsDirection='column'
      >
        <Grid container spacing={3}>
          {frequencyItems.map((item) => (
            <Grid item>
              <Grid container direction='column' alignItems='center'>
                <Grid item>
                  <Button onClick={() => handleChangeField(item)} className={clsx(classes.frequency, frequency === item && 'selected')}>
                    {item}
                  </Button>
                </Grid>
                <Grid item>
                  <Typography variant='h6' fontWeight='boldest'>
                    Days
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Dialog>
    </>
  );
};
