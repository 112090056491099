import { CircularProgress } from '@material-ui/core';
import { SubscriptionModel } from '../../../../../models/subscription.model';
import { Button } from '../../../../../components/button/Button';
import { useContext, useState } from 'react';
import { API } from 'aws-amplify';
import { SubscriptionsContext } from '../../../../../contexts/subscriptions/SubscriptionsContext';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Dialog } from '../../../../../components/dialog/Dialog';
import { useSnackbar } from '../../../../../hooks/useSnackbar/useSnackbar';

export const EditNextShipmentDialog = ({
  isOpen,
  onClose,
  subscription,
}: {
  isOpen: boolean;
  onClose: () => void;
  subscription: SubscriptionModel;
}) => {
  const { open: openSnackbar } = useSnackbar();
  const { updateSubscription } = useContext(SubscriptionsContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [nextRenewalDate, setNextRenewalDate] = useState<Date>(new Date(subscription.nextRenewal));

  const handleUpdateNextShipmentDate = async () => {
    setIsUpdating(true);

    try {
      const response = await API.put('subscriber', `/subscriptions/${subscription.id}`, {
        body: { nextRenewal: nextRenewalDate.getTime() },
      });

      if (response.subscription && updateSubscription) {
        updateSubscription(response.subscription);
      }

      openSnackbar({
        title: 'Next Shipment Date Updated Successfully',
        severity: 'success',
      });
    } catch (error) {
      openSnackbar({
        title: 'Next Shipment Date Update Failed',
        severity: 'error',
      });
    } finally {
      setIsUpdating(false);
      handleClose();
    }
  };

  const handleClose = () => {
    if (isUpdating) return;

    onClose();
  };

  const today = new Date();

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title='Set Next Shipment'
      actionButtons={
        <>
          <Button
            variant='contained'
            color='primary'
            onClick={handleUpdateNextShipmentDate}
            disabled={isUpdating}
            startIcon={isUpdating && <CircularProgress size={20} color='secondary' thickness={4} />}
            fullWidth
          >
            Save Date
          </Button>
          <Button variant='text' color='primary' onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </>
      }
      actionButtonsDirection='column'
    >
      <DatePicker
        orientation='landscape'
        openTo='date'
        variant='static'
        value={nextRenewalDate}
        onChange={(e: MaterialUiPickersDate) => setNextRenewalDate(e as Date)}
        minDate={new Date(today.setDate(today.getDate() + 1))}
        disableToolbar
      />
    </Dialog>
  );
};
