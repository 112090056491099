import { Box } from '@material-ui/core';
import { Typography } from '../../../../../components/typography/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing }) => ({
  metadata: {
    borderColor: 'rgba(0, 0, 0, 0.18)',
    padding: spacing(0.5, 1.5),
    backgroundColor: 'rgba(189, 189, 189, 0.22)',
  },
  textRed: {
    color: '#c8202f',
  },
  discount: {
    marginLeft: spacing(1),
    color: '#747474',
    textDecoration: 'line-through',
  },
}));

interface FlavorItemMeta {
  compareAtPrice: string;
  compareAtPriceFormatted: string;
  compareSavingsPct: string;
  price: string;
  priceFormatted: string;
}

export const FlavorItemMetaBox = ({ data }: { data: FlavorItemMeta }) => {
  const classes = useStyles();

  if (!data) return null;

  return (
    <Box
      border='1px dashed'
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      borderRadius={4}
      className={classes.metadata}
      mt={2}
    >
      <Typography variant='caption' fontWeight='boldest'>
        You re saving <span className={classes.textRed}>{data.compareSavingsPct}%</span>
      </Typography>
      <Box display='flex' alignItems='center'>
        <Typography variant='caption' fontWeight='boldest'>
          Only {data.priceFormatted}/each
        </Typography>
        <Typography variant='caption' fontWeight='boldest' className={classes.discount}>
          {data.compareAtPriceFormatted}
        </Typography>
      </Box>
    </Box>
  );
};
