import { AppBar, Avatar, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import DrawerComponent from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Auth } from 'aws-amplify';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { PackageIcon } from '../icons/PackageIcon';
import { LogoutIcon } from '../icons/LogoutIcon';
import { LogoIcon } from '../icons/LogoIcon';
import { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  mobileAppBar: {
    display: 'flex',
    background: 'white',
    height: 64,
    justifyContent: 'center',
    zIndex: 1350,
    borderBottom: '1px solid #e7e7e7',
    boxShadow: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerMobile: {
    width: '100%',
    maxWidth: 480,
    height: 'calc(100% - 64px)',
    top: 64,
  },
  drawerDesktop: {
    width: 279,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  logoMobile: {
    flex: '1',
    color: '#121212',
    display: 'flex',
  },
  logo: {
    padding: 20,
  },
  logoIcon: {
    width: 113,
    height: 36,
  },
  navigationItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  userInfo: {
    paddingLeft: 26,
    height: 100,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 16,
    width: 32,
    height: 32,
    fontSize: 12,
    fontWeight: 900,
  },
  welcomeLabel: {
    fontSize: 14,
  },
  username: {
    fontSize: 14,
    fontWeight: 900,
  },
  navigationItemsBottom: {
    borderTop: '1px dashed #cccbcb',
    paddingTop: 32,
  },
  navigationItem: {
    paddingLeft: 32,

    '&:hover': {
      backgroundColor: 'hsla(225, 0%, 88%, 0.18)',
      fontSize: 12,
      transition: 'all 200ms ease',
      paddingLeft: 37,
    },

    '&.selected': {
      paddingLeft: 29,
      backgroundColor: '#f0f0f0',
      borderLeft: '3px solid #000',
    },
  },
  navigationItemLabel: {
    fontSize: 12,
  },
  listItemIcon: {
    minWidth: 24,
    marginRight: 16,
  },
  navigationTopItemLabel: {
    fontWeight: 500,
  },
}));

const DrawerItems = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { user } = useAuthenticator();

  const handleSignout = () => {
    Auth.signOut();
  };

  const { family_name, given_name } = user?.attributes || ({} as any);

  const isSubscriptions = pathname.startsWith('/subscriptions');

  return (
    <div className={classes.navigationItems}>
      <List>
        <div className={classes.userInfo}>
          <Avatar className={classes.avatar}>
            {given_name?.charAt(0)} {family_name?.charAt(0)}
          </Avatar>
          <div>
            <Typography className={classes.welcomeLabel}>Welcome</Typography>
            <Typography className={classes.username}>
              {given_name} {family_name}
            </Typography>
          </div>
        </div>
        <ListItem
          component={Link}
          to='/home'
          button
          title='Subscriptions'
          className={clsx(classes.navigationItem, isSubscriptions ? 'selected' : '')}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <PackageIcon width='24' />
          </ListItemIcon>
          <ListItemText
            primary='My Subscriptions'
            classes={{
              primary: clsx(classes.navigationItemLabel, classes.navigationTopItemLabel),
            }}
          />
        </ListItem>
      </List>
      <List className={classes.navigationItemsBottom}>
        <ListItem button onClick={handleSignout} title='Logout' className={classes.navigationItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <LogoutIcon width='24' />
          </ListItemIcon>
          <ListItemText
            primary='Logout'
            classes={{
              primary: classes.navigationItemLabel,
            }}
          />
        </ListItem>
      </List>
    </div>
  );
};

export const Drawer = () => {
  const classes = useStyles();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  return (
    <>
      <AppBar position='fixed' className={classes.mobileAppBar}>
        <Toolbar>
          <div className={classes.logoMobile}>
            <LogoIcon className={classes.logoIcon} />
          </div>
          <IconButton onClick={() => setMobileDrawerOpen(!mobileDrawerOpen)}>{mobileDrawerOpen ? <CloseIcon /> : <MenuIcon />}</IconButton>
        </Toolbar>
      </AppBar>
      <DrawerComponent
        variant='temporary'
        anchor='right'
        open={mobileDrawerOpen}
        onClose={() => setMobileDrawerOpen(false)}
        classes={{
          paper: classes.drawerMobile,
        }}
      >
        <DrawerItems />
      </DrawerComponent>
      <DrawerComponent
        variant='permanent'
        className={classes.drawerDesktop}
        classes={{
          paper: classes.drawerDesktop,
        }}
      >
        <div className={classes.logo}>
          <LogoIcon className={classes.logoIcon} />
        </div>
        <DrawerItems />
      </DrawerComponent>
    </>
  );
};
