import { useQuery } from '../../../hooks/useQuery/useQuery';
import { Card, CircularProgress, FormControl, Grid } from '@material-ui/core';
import CoffeeIcon from '../../../assets/icons/coffee.svg';
import { Typography } from '../../../components/typography/Typography';
import { TextField } from '../../../components/fields/textField/TextField';
import { Button } from '../../../components/button/Button';
import { makeStyles } from '@material-ui/core/styles';
import { ChangeEvent, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useSnackbar } from '../../../hooks/useSnackbar/useSnackbar';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    backgroundColor: '#f9f9f9',
  },
  formWrapper: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    margin: 'auto',
    top: '50%',
    left: '50%',
  },
  card: {
    maxWidth: 450,
    padding: 42,
    border: '1px solid #ddd',
    boxShadow: 'none',
  },
  title: {
    marginBottom: 16,
  },
  formControl: {
    display: 'block',
    marginBottom: 22,
  },
  resetPasswordButton: {
    height: 45,
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    width: '100%',
    boxShadow: 'none',
    minWidth: 280,

    [theme.breakpoints.up('sm')]: {
      minWidth: 360,
    },
  },
}));

export const ResetPassword = () => {
  const query = useQuery();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });
  const { open: openSnackbar } = useSnackbar();

  const handleResetPassword = async () => {
    try {
      setLoading(true);
      if (formData.password !== formData.confirmPassword || formData.password.length < 8) {
        throw new Error('Invalid Password');
      }

      const email = query.get('email');
      const code = query.get('code');

      if (!email) {
        throw new Error('Invalid Email');
      }

      if (!code) {
        throw new Error('Invalid token');
      }

      await Auth.forgotPasswordSubmit(email, code, formData.password);

      openSnackbar({
        title: 'Password Updated',
        severity: 'success',
      });

      window.location.replace('/signin');
    } catch (error) {
      openSnackbar({
        title: 'Failed to Reset Password',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [key]: event.target.value,
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.formWrapper}>
        <Card className={classes.card}>
          <Grid container className={classes.title} alignItems='center' spacing={2}>
            <Grid item>
              <img src={CoffeeIcon} width={30} alt='coffee' />
            </Grid>
            <Grid item>
              <Typography variant='h3' fontWeight='boldest'>
                Reset Password!
              </Typography>
            </Grid>
          </Grid>
          <FormControl className={classes.formControl}>
            <TextField
              id='password'
              type='password'
              label='New Password'
              value={formData.password}
              onChange={handleChange('password')}
              fullWidth
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id='confirmPassowrd'
              type='password'
              label='Confirm New Passowrd'
              value={formData.confirmPassword}
              onChange={handleChange('confirmPassword')}
              fullWidth
            />
          </FormControl>
          <Button
            variant='contained'
            color='primary'
            className={classes.resetPasswordButton}
            onClick={handleResetPassword}
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} color='secondary' thickness={4} />}
          >
            Reset And Login Now
          </Button>
        </Card>
      </div>
    </div>
  );
};
