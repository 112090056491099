import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { SnackbarSeverity } from '../../components/snackbar/Snackbar';

export type SnackbarContextType = {
  snackbarState: SnackbarStateType;
  setSnackbarState: Dispatch<SetStateAction<SnackbarStateType>>;
};

export type SnackbarStateProps = {
  severity: SnackbarSeverity;
  title: ReactNode;
  autoHideDuration?: number;
};

export type SnackbarStateType = {
  isOpen: boolean;
  props: SnackbarStateProps;
};

export const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackbarState, setSnackbarState] = useState<SnackbarStateType>({
    isOpen: false,
    props: {
      severity: 'success',
      title: '',
    },
  });

  return <SnackbarContext.Provider value={{ snackbarState, setSnackbarState }}>{children}</SnackbarContext.Provider>;
};
