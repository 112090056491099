import { Routes, Route, Navigate } from 'react-router-dom';

import { Authorization } from '../templates/authorization/Authorization';
import { Dashboard } from '../templates/dashboard/Dashboard';
import { Signin } from '../views/auth/signin/Signin';
import { MySubscriptions } from '../views/mySubscriptions/MySubscriptions';
import { ForgotPassword } from '../views/auth/forgotPassword/ForgotPassword';
import { ResetPassword } from '../views/auth/resetPassword/ResetPassword';

export const AppRoutes = () => (
  <Routes>
    <Route path='/subscriptions' element={<Dashboard element={<MySubscriptions />} />} />
    <Route path='/signin' element={<Authorization element={<Signin />} />} />
    <Route path='/forgot-password' element={<Authorization element={<ForgotPassword />} />} />
    <Route path='/confirm_resetpw' element={<Authorization element={<ResetPassword />} />} />
    <Route path='/*' element={<Navigate to='/subscriptions' />} />
  </Routes>
);
