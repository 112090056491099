import { useContext } from 'react';

import { SubscriptionsContext } from '../../contexts/subscriptions/SubscriptionsContext';
import { LoadingSpinner } from '../../components/loadingSpinner/LoadingSpinner';
import { SubscriptionsHeader } from './header/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { SubscriptionDetailsCard } from './details/Details';

export const MySubscriptions = () => {
  const { loading, subscriptions } = useContext(SubscriptionsContext);
  const [selectedSubscriptionIndex, setSelectedSubscriptionIndex] = useState<number>();

  useEffect(() => {
    if (subscriptions && subscriptions.length > 0) {
      setSelectedSubscriptionIndex(0);
    } else {
      setSelectedSubscriptionIndex(undefined);
    }
  }, [subscriptions]);

  return (
    <>
      {!loading && (
        <>
          <SubscriptionsHeader
            subscriptions={subscriptions}
            selectedSubscriptionIndex={selectedSubscriptionIndex}
            onSelectSubscription={setSelectedSubscriptionIndex}
          />
          {selectedSubscriptionIndex !== undefined && <SubscriptionDetailsCard subscriptionIndex={selectedSubscriptionIndex} />}
        </>
      )}
      {/* SHOW NO SUBSCRIPTIONS IF SUBSCRIPTIONS LENGTH ZERO */}
      {loading && <LoadingSpinner />}
    </>
  );
};
