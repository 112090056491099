import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, FormControl, Grid } from '@material-ui/core';
import { SubscriptionModel } from '../../../../../models/subscription.model';
import { ChangeEvent, useContext, useState } from 'react';
import { SubscriptionsContext } from '../../../../../contexts/subscriptions/SubscriptionsContext';
import { CardInfoModel } from '../../../../../models/cardInfo.model';
import { API } from 'aws-amplify';
import { TextField } from '../../../../../components/fields/textField/TextField';
import { Button } from '../../../../../components/button/Button';
import { Dialog } from '../../../../../components/dialog/Dialog';
import { useSnackbar } from '../../../../../hooks/useSnackbar/useSnackbar';

const useStyles = makeStyles({
  formControl: {
    display: 'block',
    marginBottom: 22,
  },
});

export const EditDebitCreditCardDialog = ({
  isOpen,
  onClose,
  subscription,
}: {
  isOpen: boolean;
  onClose: () => void;
  subscription: SubscriptionModel;
}) => {
  const { open: openSnackbar } = useSnackbar();
  const { updateSubscription } = useContext(SubscriptionsContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const classes = useStyles();
  const [formData, setFormData] = useState<Partial<CardInfoModel>>({});

  const handleChangeField = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  const handleClose = () => {
    if (isUpdating) return;

    onClose();
  };

  const handleUpdateCard = async () => {
    setIsUpdating(true);

    try {
      const response = await API.post('subscriber', `/subscriptions/${subscription.id}/update_billing`, {
        body: {
          ...formData,
          billing: subscription.billingAddress,
        },
      });

      if (response.subscription && updateSubscription) {
        updateSubscription(response.subscription);
      }

      openSnackbar({
        title: 'Card Updated Successfully',
        severity: 'success',
      });
    } catch (error) {
      openSnackbar({
        title: 'Card Update Failed',
        severity: 'error',
      });
    } finally {
      setIsUpdating(false);
      handleClose();
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title='Edit Credit Card'
      actionButtonsDirection='column'
      actionButtons={
        <>
          <Button
            variant='contained'
            color='primary'
            onClick={handleUpdateCard}
            disabled={
              isUpdating ||
              formData.cc_number === '' ||
              formData.cc_exp_month === '' ||
              formData.cc_exp_year === '' ||
              formData.cc_cvv === ''
            }
            startIcon={isUpdating && <CircularProgress size={20} color='secondary' thickness={4} />}
            fullWidth
          >
            Save
          </Button>
          <Button variant='outlined' color='primary' onClick={handleClose} fullWidth>
            Cancel
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <TextField
              id='cc_number'
              name='cc_number'
              label='Credit Card Number'
              value={formData.cc_number}
              onChange={handleChangeField('cc_number')}
              fullWidth
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formControl}>
            <TextField
              id='cc_exp_month'
              name='cc_exp_month'
              label='Expiration Month'
              value={formData.cc_exp_month}
              onChange={handleChangeField('cc_exp_month')}
              fullWidth
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formControl}>
            <TextField
              id='cc_exp_year'
              name='cc_exp_year'
              label='Expiration Year'
              value={formData.cc_exp_year}
              onChange={handleChangeField('cc_exp_year')}
              fullWidth
              required
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.formControl}>
            <TextField
              id='cc_cvv'
              name='cc_cvv'
              label='CVV'
              value={formData.cc_cvv}
              onChange={handleChangeField('cc_cvv')}
              fullWidth
              required
            />
          </FormControl>
        </Grid>
      </Grid>
    </Dialog>
  );
};
