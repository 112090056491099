import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { SubscriptionModel } from '../../../../../models/subscription.model';
import { useContext, useState } from 'react';
import { SubscriptionsContext } from '../../../../../contexts/subscriptions/SubscriptionsContext';
import { Typography } from '../../../../../components/typography/Typography';
import { format } from 'date-fns';
import { API } from 'aws-amplify';
import { Button } from '../../../../../components/button/Button';
import { fontWeight } from '../../../../../theme/typography';
import { Dialog } from '../../../../../components/dialog/Dialog';
import { useSnackbar } from '../../../../../hooks/useSnackbar/useSnackbar';

const useStyles = makeStyles({
  titleContainer: {
    padding: 32,
    borderBottom: '1px dashed #d6d6d6',
  },
  title: {
    fontSize: 24,
    lineHeight: '130%',
    letterSpacing: -0.5,
    fontWeight: fontWeight.boldest,
  },
  content: {
    padding: 32,
    paddingBottom: 0,
  },
  newRenewalDate: {
    color: '#121212',
    fontWeight: fontWeight.boldest,
  },
  actions: {
    padding: 32,
    flexDirection: 'column',

    '&.MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: 0,
      marginTop: 16,
    },
  },
  actionButton: {
    '&.MuiButton-root': {
      fontWeight: fontWeight.bolder,
    },
  },
  cancelButton: {
    fontSize: 10,
  },
});

export const CancelSubscriptionConfirmDialog = ({
  isOpen,
  onClose,
  subscription,
}: {
  isOpen: boolean;
  onClose: () => void;
  subscription: SubscriptionModel;
}) => {
  const { open: openSnackbar } = useSnackbar();
  const classes = useStyles();
  const { getSubscriptions, updateSubscription } = useContext(SubscriptionsContext);
  const [isSkippingNextShipment, setIsSkippingNextShipment] = useState(false);
  const [isCancellingSubscription, setIsCancellingSubscription] = useState(false);

  const handleClose = () => {
    if (isSkippingNextShipment || isCancellingSubscription) return;

    onClose();
  };

  const onSkipNextShipment = async () => {
    setIsSkippingNextShipment(true);

    try {
      const newRenewalDate = subscription.nextRenewal + 86400 * 1000 * subscription.renewalPeriodDays;
      const response = await API.put('subscriber', `/subscriptions/${subscription.id}`, {
        body: { nextRenewal: newRenewalDate },
      });

      if (response.subscription && updateSubscription) {
        updateSubscription(response.subscription);
      }

      openSnackbar({
        title: 'Shipment Skipped',
        severity: 'success',
      });
    } catch (error) {
      openSnackbar({
        title: 'Shipment Skip Failed',
        severity: 'error',
      });
    } finally {
      setIsSkippingNextShipment(false);
      onClose();
    }
  };

  const onCancelSubscription = async () => {
    setIsCancellingSubscription(true);

    try {
      const newRenewalDate = -1;
      await API.put('subscriber', `/subscriptions/${subscription.id}`, {
        body: { nextRenewal: newRenewalDate },
      });

      openSnackbar({
        title: 'Subscription Cancelled Successfully',
        severity: 'success',
      });

      if (getSubscriptions) {
        await getSubscriptions();
      }
    } catch (error) {
      openSnackbar({
        title: 'Cancel Subscription Failed',
        severity: 'error',
      });
    } finally {
      setIsCancellingSubscription(false);
      onClose();
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      maxWidth='xs'
      title='Are you sure you want to cancel your subscription?'
      actionButtonsDirection='column'
      actionButtons={
        <>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            className={classes.actionButton}
            onClick={handleClose}
            disabled={isCancellingSubscription || isSkippingNextShipment}
          >
            No, keep my subscription
          </Button>
          <Button
            variant='outlined'
            fullWidth
            className={classes.actionButton}
            onClick={onSkipNextShipment}
            disabled={isCancellingSubscription || isSkippingNextShipment}
            startIcon={isSkippingNextShipment && <CircularProgress size={20} color='secondary' thickness={4} />}
          >
            Skip next shipment instead
          </Button>
          <Button
            variant='text'
            fullWidth
            onClick={onCancelSubscription}
            disabled={isCancellingSubscription || isSkippingNextShipment}
            startIcon={isCancellingSubscription && <CircularProgress size={20} color='secondary' thickness={4} />}
            className={clsx(classes.actionButton, classes.cancelButton)}
          >
            Yes, cancel it
          </Button>
        </>
      }
    >
      <Typography variant='h5' color='textSecondary' fontWeight='medium'>
        If you cancel your subscription, you will stop receiving orders since{' '}
        <span className={classes.newRenewalDate}>{format(subscription.nextRenewal, 'MMM do, yyyy')}</span>.
      </Typography>
    </Dialog>
  );
};
