import { makeStyles } from '@material-ui/core';
import CardBaseComponent, { CardProps as CardBaseComponentProps } from '@material-ui/core/Card';
import clsx from 'clsx';

interface CardProps extends CardBaseComponentProps {}

const useStyles = makeStyles({
  root: {
    border: '1px solid #e7e7e7',
    boxShadow: 'none',
  },
});

export const Card = ({ children, className, ...props }: CardProps) => {
  const classes = useStyles();

  return (
    <CardBaseComponent className={clsx(classes.root, className)} {...props}>
      {children}
    </CardBaseComponent>
  );
};
