import { useCallback, useContext, useEffect } from 'react';
import { SnackbarContext } from '../../contexts/snackbar/SnackbarContext';
import { Snackbar as SnackbarBase } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export type SnackbarSeverity = 'error' | 'warning' | 'info' | 'success';

export type SnackbarColors = 'red' | 'green' | 'blue' | 'orange' | 'black';

export const Snackbar = () => {
  const context = useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error('SnackbarContext must be used within an SnacbarContextController');
  }

  const {
    setSnackbarState,
    snackbarState: {
      isOpen,
      props: { severity, title, autoHideDuration },
    },
  } = context;

  const onClose = useCallback(() => {
    setSnackbarState((s) => ({ ...s, isOpen: false }));
  }, [setSnackbarState]);

  useEffect(() => {
    if (isOpen && !!autoHideDuration) {
      setTimeout(() => {
        onClose();
      }, autoHideDuration);
    }
  }, [isOpen, autoHideDuration, onClose]);

  return (
    <SnackbarBase
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{
        zIndex: 2000,
      }}
    >
      <Alert severity={severity} onClose={onClose}>
        {title}
      </Alert>
    </SnackbarBase>
  );
};
