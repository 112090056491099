import { Box, CircularProgress, Grid, makeStyles, useTheme } from '@material-ui/core';
import { Typography } from '../../../../components/typography/Typography';
import { Button } from '../../../../components/button/Button';
import { API } from 'aws-amplify';
import { useContext, useState } from 'react';
import { SubscriptionModel } from '../../../../models/subscription.model';
import { SubscriptionsContext } from '../../../../contexts/subscriptions/SubscriptionsContext';
import { format } from 'date-fns';
import { EditShippingAddressDialog } from './editShippingAddressDialog/EditShippingAddressDialog';
import { EditDebitCreditCardDialog } from './editDebitCreditCardDialog/EditDebitCreditCardDialog';
import { EditNextShipmentDialog } from './editNextShipmentDialog/EditNextShipmentDialog';
import { EditFrequencyDialog } from './editFrequencyDialog/EditFrequencyDialog';
import { CancelSubscriptionConfirmDialog } from './cancelSubscriptionConfirmDialog/CancelSubscriptionConfirmDialog';
import { useSnackbar } from '../../../../hooks/useSnackbar/useSnackbar';

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    padding: `0 ${spacing(2)}px`,
  },
  card: {
    backgroundColor: '#f7f7f7',
    borderRadius: spacing(1),
    padding: spacing(3),
  },
  billingInfoCard: {
    border: '1px dashed',
    borderRadius: spacing(1),
    padding: spacing(3),
  },
  billingInfoCardTitle: {
    fontSize: 10,
  },
  editBillingInfoButton: {
    borderRadius: 4,
    border: `2px solid #121212`,
    fontSize: 10,
    fontWeight: 700,
  },
  skipShipmentButton: {
    borderColor: '#000',
    borderWidth: 2,
  },
  cancelSubscriptionButton: {
    fontSize: 10,
  },
}));

export const ManageSubscription = ({ subscription }: { subscription: SubscriptionModel }) => {
  const classes = useStyles();
  const { spacing } = useTheme();
  const { updateSubscription } = useContext(SubscriptionsContext);
  const [isSkippingNextShipment, setIsSkippingNextShipment] = useState(false);
  const [isShippingNow, setIsShippingNow] = useState(false);
  const [isEditShippingAddressModalOpen, setIsEditShippingAddressModalOpen] = useState(false);
  const [isEditDebitCreditCardModalOpen, setIsEditDebitCreditCardModalOpen] = useState(false);
  const [isEditNextShipmentModalOpen, setIsEditNextShipmentModalOpen] = useState(false);
  const [isEditFrequencyModalOpen, setIsEditFrequencyModalOpen] = useState(false);
  const [isCancelSubscriptionConfirmModalOpen, setIsCancelSubscriptionConfirmModalOpen] = useState(false);
  const { open: openSnackbar } = useSnackbar();

  const onSkipNextShipment = async () => {
    setIsSkippingNextShipment(true);

    try {
      const newRenewalDate = subscription.nextRenewal + 86400 * 1000 * subscription.renewalPeriodDays;
      const response = await API.put('subscriber', `/subscriptions/${subscription.id}`, {
        body: { nextRenewal: newRenewalDate },
      });

      if (response.subscription && updateSubscription) {
        updateSubscription(response.subscription);
      }

      openSnackbar({
        title: 'Shipment Skipped',
        severity: 'success',
      });
    } catch (error) {
      openSnackbar({
        title: 'Shipment Skip Failed',
        severity: 'error',
      });
    } finally {
      setIsSkippingNextShipment(false);
    }
  };

  const onShipNow = async () => {
    setIsShippingNow(true);

    try {
      const newRenewalDate = new Date().getTime() + 15 * 60 * 1000;
      const response = await API.put('subscriber', `/subscriptions/${subscription.id}`, {
        body: { nextRenewal: newRenewalDate },
      });

      if (response.subscription && updateSubscription) {
        updateSubscription(response.subscription);

        openSnackbar({
          title: 'Shipped Now',
          severity: 'success',
        });
      }
    } catch (error) {
      openSnackbar({
        title: 'Ship Now Failed',
        severity: 'error',
      });
    } finally {
      setIsShippingNow(false);
    }
  };

  return (
    <>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} md={8}>
          <Typography variant='h4' fontWeight='boldest'>
            Billing &#38; Shipping Details
          </Typography>
          <Box height={spacing(3)} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box className={classes.billingInfoCard}>
                <Typography color='textSecondary' fontWeight='bolder' className={classes.billingInfoCardTitle}>
                  SHIPPING ADDRESS
                </Typography>
                <Box height={spacing(1)} />
                <Typography variant='caption' fontWeight='bolder'>
                  {subscription.shippingAddress.address1} {subscription.shippingAddress.address2}
                  <br />
                  {subscription.shippingAddress.city}, {subscription.shippingAddress.province} {subscription.shippingAddress.zip}
                  <br />
                  {subscription.shippingAddress.country}
                </Typography>
                <Box height={spacing(1)} />
                <Button
                  variant='outlined'
                  className={classes.editBillingInfoButton}
                  onClick={() => setIsEditShippingAddressModalOpen(true)}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.billingInfoCard}>
                <Typography color='textSecondary' fontWeight='bolder' className={classes.billingInfoCardTitle}>
                  DEBIT/CREDIT CARD
                </Typography>
                <Box height={spacing(1)} />
                <Button
                  variant='outlined'
                  className={classes.editBillingInfoButton}
                  onClick={() => setIsEditDebitCreditCardModalOpen(true)}
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.billingInfoCard}>
                <Typography color='textSecondary' fontWeight='bolder' className={classes.billingInfoCardTitle}>
                  NEXT SHIPMENT
                </Typography>
                <Box height={spacing(1)} />
                <Typography variant='caption' fontWeight='bolder'>
                  {format(subscription.nextRenewal, 'MMM do')}
                </Typography>
                <Box height={spacing(1)} />
                <Button variant='outlined' className={classes.editBillingInfoButton} onClick={() => setIsEditNextShipmentModalOpen(true)}>
                  Edit
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={classes.billingInfoCard}>
                <Typography color='textSecondary' fontWeight='bolder' className={classes.billingInfoCardTitle}>
                  FREQUENCY
                </Typography>
                <Box height={spacing(1)} />
                <Typography variant='caption' fontWeight='bolder'>
                  {subscription.renewalPeriodDays} Days
                </Typography>
                <Box height={spacing(1)} />
                <Button variant='outlined' className={classes.editBillingInfoButton} onClick={() => setIsEditFrequencyModalOpen(true)}>
                  Edit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant='h4' fontWeight='boldest'>
            Manage Details
          </Typography>
          <Box mt={3} className={classes.card}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={onShipNow}
              disabled={isShippingNow}
              startIcon={isShippingNow && <CircularProgress size={20} color='secondary' thickness={4} />}
            >
              Ship Now
            </Button>
            <Box height={spacing(2)} />
            <Button
              variant='outlined'
              fullWidth
              onClick={onSkipNextShipment}
              disabled={isSkippingNextShipment}
              startIcon={isSkippingNextShipment && <CircularProgress size={20} color='secondary' thickness={4} />}
              className={classes.skipShipmentButton}
            >
              Skip Shipment
            </Button>
            <Box height={spacing(2)} />
            <Button
              variant='text'
              fullWidth
              onClick={() => setIsCancelSubscriptionConfirmModalOpen(true)}
              className={classes.cancelSubscriptionButton}
            >
              Cancel Subscription
            </Button>
          </Box>
        </Grid>
      </Grid>
      {isCancelSubscriptionConfirmModalOpen && (
        <CancelSubscriptionConfirmDialog
          isOpen={isCancelSubscriptionConfirmModalOpen}
          onClose={() => setIsCancelSubscriptionConfirmModalOpen(false)}
          subscription={subscription}
        />
      )}
      {isEditShippingAddressModalOpen && (
        <EditShippingAddressDialog
          isOpen={isEditShippingAddressModalOpen}
          onClose={() => setIsEditShippingAddressModalOpen(false)}
          subscription={subscription}
        />
      )}
      {isEditDebitCreditCardModalOpen && (
        <EditDebitCreditCardDialog
          isOpen={isEditDebitCreditCardModalOpen}
          onClose={() => setIsEditDebitCreditCardModalOpen(false)}
          subscription={subscription}
        />
      )}
      {isEditNextShipmentModalOpen && (
        <EditNextShipmentDialog
          isOpen={isEditNextShipmentModalOpen}
          onClose={() => setIsEditNextShipmentModalOpen(false)}
          subscription={subscription}
        />
      )}
      {isEditFrequencyModalOpen && (
        <EditFrequencyDialog
          isOpen={isEditFrequencyModalOpen}
          onClose={() => setIsEditFrequencyModalOpen(false)}
          subscription={subscription}
        />
      )}
    </>
  );
};
